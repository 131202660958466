<template>
  <div class="col-12">
    <q-img
      class="rounded-borders"
      :src="urlThumbnail"
      spinner-color="primary"
      @click="fullscreen = !fullscreen"
    >
      <template #loading>
        <q-spinner-ios color="primary" />
      </template>
    </q-img>

    <vue-easy-lightbox
      teleport="body"
      :visible="fullscreen"
      :imgs="[{ src: urlImageView, title: imageName }]"
      @hide="closeLightbox"
    />
  </div>
</template>

<script>
import { urlImageView } from '@/aws/api'

export default {
  props: {
    size: {},
    token: {},
    image: {}
  },

  data () {
    const fullscreen = false
    return { fullscreen }
  },

  computed: {
    imageName () {
      const imageName = String(this.image?.name || '').trim().split('.')
      imageName.pop()
      return imageName.join('.')
    },

    urlImageView () {
      return urlImageView(this.image.id, this.token)
    },

    urlThumbnail () {
      const thumb = getThumbnail(this.image, this.size)
      return thumb ?? this.urlImageView
    }
  },

  methods: {
    closeLightbox () {
      this.fullscreen = false
    }
  }
}

export const getThumbnail = (image, size) => {
  const resizes = Array.isArray(image.resizes) ? image.resizes : []
  const resize = resizes.find(({ name }) => name === size)
  return resize?.url
}

</script>
