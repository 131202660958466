<template>
  <div class="row">
    <div
      class="col-12 q-mt-sm"
      v-if="token"
    >
      <q-carousel
        swipeable
        animated
        arrows
        v-model="slide"
        :fullscreen.sync="fullscreen"
        infinite
        height="150px"
      >
        <template v-for="image in images">
          <q-carousel-slide
            :name="image.id"
            :key="image.id"
            class="row no-wrap q-pa-none cursor-pointer"
          >
            <FieldImageItem
              :image="image"
              :size="size"
              :token="token"
            />
          </q-carousel-slide>
        </template>
      </q-carousel>
    </div>
  </div>
</template>

<script>
import { getTokenJWT } from '@/aws/getAuthSession'
import FieldImageItem from './FieldImageItem'

export default {
  components: {
    FieldImageItem
  },

  props: {
    size: {},
    images: {}
  },

  data () {
    const slide = this.images[0].id
    const token = ''
    const fullscreen = false
    return { slide, token, fullscreen }
  },

  mounted () {
    getTokenJWT().then((token) => {
      this.token = token
    })
  }
}

</script>
